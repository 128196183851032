<template>
  <div>
    <div class="bannerContainer">
      <div class="w" :class="$i18n.locale == 'en' ? 'en_banner' : 'zh_banner'">
        <div class="title">
          <div>{{ $t('光伏铺装') }}</div>
          <div>{{ $t('photovoltaicLayingSolution.解决方案') }}</div>
        </div>
        <div class="info">{{ $t('大幅度的提高了铺板的作业效率。') }}</div>
      </div>
    </div>

    <div class="scheme">
      <div class="schemeInfo">
        <div class="title">{{ $t('方案简介') }}</div>
        <div class="info">
          {{
            $t(
              '用3D相机引导机械臂对光伏面板进行抓取，将光伏面板精确放到指定的安装位置与姿态。Tensor Eye在2m距离实现了Z值误差&lt;3mm，机械臂综合引导误差&lt;1cm ，扫描成像时间缩短到2s以内，定位精度的提升大幅度的提高了铺板的作业效率。'
            )
          }}
        </div>
      </div>
      <div class="img w">
        <img src="../../../assets/光伏铺装解决方案/方案简介.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.bannerContainer {
  background: url(../../../assets/光伏铺装解决方案/安装解决方案2.png.png)
    no-repeat center;
  background-size: cover;
  .zh_banner {
    padding: 122px 0px 0px 134px;
    height: 480px;
    color: #fff;
    .title {
      line-height: 80px;
      font-size: 72px;
      font-weight: 700;
    }
    .info {
      margin-top: 11px;
      color: #fff;
      font-size: 24px;
      height: 83px;
      font-size: 24px;
      line-height: 34.75px;
    }
  }
  .en_banner {
    padding: 122px 0px 0px 134px;
    height: 480px;
    color: #fff;
    .title {
      line-height: 69.5px;
      font-size: 48px;
      font-weight: 700;
    }
    .info {
      width: 402px;
      color: #fff;
      font-size: 24px;
      font-size: 24px;
      line-height: 34.75px;
    }
  }
}

.scheme {
  background: #f5f6fa;
  .title {
    padding: 45px 0px 30px 0px;
    font-size: 25px;
    font-weight: 500;
    line-height: 36.2px;
    color: rgba(52, 79, 152, 1);
  }
  .schemeInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    .info {
      text-align: justify;
      width: 1083px;
      font-size: 20px;
      font-weight: 400;
      line-height: 28.96px;
      color: rgba(128, 128, 128, 1);
    }
  }
  .img {
    padding: 30px 141px 41px 138px;
    img {
      width: 100%;
    }
  }
}
</style>
